@import "styles/variables";

.newsletter{

  display: flex;
  justify-content: center;

  & > div:first-child{
    width: 580px;
    @media (max-width: $lg) {
      width : 100%;
    }
  }

  & > div:last-child{
    width: 100px;
  }

}