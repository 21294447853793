.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}