@import "styles/variables";

.header {
  .infoBar {
    color: $text_white;
    background-color: $c_red_bg;
    font-size: 20px;
    font-weight: bold;

    p {
      padding: 10px 0;
      margin: 0;

      a {
        margin-left: 15px;

        &:hover {
          text-decoration: underline;

          svg {
            transform: translateX(5px);

            path {
              width: 15px;
            }
          }
        }

        svg {
          transition: transform 0.2s ease;
          margin-left: 8px;

          path {
            fill: $c_white;
          }
        }
      }
    }
  }

  .mainBarWrap {
    background-color: $c_black_bg;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;

    .hamburgerWrap,
    .mobileSearchForm {
      display: none;
    }

    .mainBar {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .brand {
      margin-right: 87px;

      img:last-child {
        display: none;
      }

      &:hover {
        img:first-child {
          display: none;
        }

        img:last-child {
          display: block;
        }
      }
    }

    .contact {
      font-size: $f_size_base;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 20px;
      border-left: 1px solid $c_dark_green_border;
      border-right: 1px solid $c_dark_green_border;

      a {
        color: $text_white;
        font-weight: bold;
        line-height: 20px;
        white-space: nowrap;
        transition: all 0.2s ease;

        svg {
          margin-right: 7px;
        }

        &:hover {
          color: $c_green;
          text-decoration: underline;
        }

        &:first-child {
          margin-bottom: 5px;
        }
      }
    }

    .iconActions {
      display: flex;
      align-items: center;
      height: 100%;

      .item {
        display: flex;
        align-items: center;
        height: 100%;
        width: 70px;
        border-right: 1px solid $c_dark_green_border;
        transition: all 0.2s ease;
        position: relative;

        .count {
          position: relative;
          margin: 0 auto;

          span {
            font-size: 14px;
            font-weight: bold;
            color: $text_white;
            background-color: $c_count_badge;
            width: 20px;
            height: 20px;
            line-height: 20px;
            position: absolute;
            right: -8px;
            top: -8px;
            border-radius: 50%;
            text-align: center;
          }
        }

        &:after {
          opacity: 0;
          content: "";
          position: absolute;
          height: 1px;
          width: 28px;
          border-bottom: 2px solid $c_black;
          left: 0;
          right: 0;
          bottom: 25px;
          margin: 2px auto 0;
          transition: opacity 0.2s ease;
        }

        svg {
          margin: 0 auto;
        }

        &:hover {
          background-color: $c_green;

          &:after {
            opacity: 1;
          }

          svg {
            fill: $c_black;

            circle {
              fill: $c_black;
            }

            path {
              fill: $c_black;
            }
          }
        }
      }

      .cart {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .iconCart {
          display: flex;
          justify-content: center;
          align-items: center;

          .cartCount {
            position: relative;
            margin: 0 auto;

            span {
              font-size: 14px;
              font-weight: bold;
              color: $text_white;
              background-color: $c_count_badge;
              width: 20px;
              height: 20px;
              line-height: 20px;
              position: absolute;
              right: -8px;
              top: -8px;
              border-radius: 50%;
              text-align: center;
            }
          }
        }

        .cartItems {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          background-color: white;
          width: 400px;
          z-index: 2;
          border-bottom-right-radius: $b_radius_default;
          border-bottom-left-radius: $b_radius_default;
          box-shadow: $general-shadow;
          padding: 20px;
          .cartItem {
            border-bottom: 1px solid $grey-color;
            padding: 10px 0;
            &:last-child {
              border-bottom: none;
            }
            & > a {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .image {
                width: 70px;
                flex-basis: 70px;
              }
              h4 {
                flex-basis: 230px;
                text-align: left;
                padding: 0 20px;
                margin: 0;
                font-size: 1em;
                line-height: 1em;
              }
              .price {
                width: 100px;
                flex-basis: 100px;
                text-align: right;
                font-weight: bold;
              }
            }
          }
          @media (max-width: $xs) {
            width: 300px;
            .cartItem {
              .image {
                display: none;
              }
              h4 {
                width: 200px;
                flex-basis: 200px;
              }
            }
          }
        }
        &:hover {
          .cartItems {
            display: block;
          }
        }
      }
    }
  }

  .categoriesWrapper {
    height: 56px;
    background-color: $c_white_bg;
    position: relative;

    .container {
      padding: 0;
    }

    ul {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      & > li {
        margin-right: 7px;
        padding: 8px 0;
        display: block;

        & > a {
          color: $text_black;
          border-radius: $b_radius_default;
          padding: 0 10px;
          font-size: 15px;
          line-height: 20px;
          font-weight: bold;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            margin-right: 10px;
            transition: all 0.3s;
          }
        }

        &.sale {
          a {
            background-color: $c_sale;
            color: $text_white;
          }
        }

        &:hover {
          & > a {
            background-color: $c_black;
            color: $text_white;

            img {
              transform: rotate(-25deg);
            }
          }

          &.sale {
            a {
              img {
                filter: none;
              }
            }
          }
        }

        &.hasMegamenu {
          &:hover {
            .megamenu {
              display: flex;
            }
          }

          .megamenu {
            display: none;
            flex-wrap: wrap;
            padding: 25px;
            // width: calc(1306px - 50px);
            background-color: $c_white_bg;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 100;
            border-bottom-right-radius: $b_radius_default;
            border-bottom-left-radius: $b_radius_default;
            box-shadow: 0 10px 10px $c_shadow;

            .categoryList {
              margin-right: 70px;
              margin-bottom: 50px;

              h4 {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 20px;

                svg,
                img {
                  margin-right: 10px;
                  width: 45px;
                }
              }

              ul {
                padding-left: 57px;
                display: block;

                li {
                  padding: 0;
                  margin: 0;

                  a {
                    padding: 2px 0;
                    font-size: $f_size_base;
                    font-weight: normal;

                    &:hover {
                      text-decoration: underline;
                      background: none;
                      color: $text_black;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $xxxl) {
  .header {
    .action-form {
      form {
        input {
          width: 280px;
        }
      }
    }

    .categoriesWrapper {
      height: 65px;

      .container {
        max-width: 100%;
        width: 100%;
      }

      ul {
        padding: 0 5px;
        justify-content: space-between;

        li {
          margin-right: 15px;

          svg {
            width: 21px;
          }

          a {
            line-height: 1.2;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: $xxl) {
  .header {
    .mainBarWrap {
      .contact {
        display: none;
      }

      .iconActions {
        .item:first-child {
          border-left: 1px solid $c_dark_green_border;
        }
      }
    }

    .categoriesWrapper {
      .container {
        padding: 0 5px;

        ul {
          justify-content: flex-start;

          li {
            margin-right: 5px;

            a {
              font-size: 14px;
              line-height: 1.2;
            }

            svg {
              width: 25px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .header {
    .mainBarWrap {
      .brand {
        margin-right: 0;
      }

      .mainBar {
        width: 960px;
        justify-content: space-between;
      }

      .iconActions {
        .item {
          display: none;
        }

        .cart {
          display: flex;
          border-right: none;
        }

        .iconCart {
          height: 51px;

          .cartCount {
            position: relative;

            span {
              font-size: 14px;
              font-weight: bold;
              color: $text_white;
              background-color: $c_count_badge;
              width: 20px;
              height: 20px;
              line-height: 20px;
              position: absolute;
              right: -8px;
              top: -8px;
              border-radius: 50%;
              text-align: center;
            }
          }
        }
      }
    }

    .categoriesWrapper {
      display: none;
    }
  }
}

@media (max-width: $lg) {
  .header {
    .infoBar {
      font-size: $f_size_base;

      p {
        padding: 8px 0;

        a {
          margin-left: 12px;
        }
      }
    }

    .mainBarWrap {
      height: auto; //140px;
      flex-direction: column;
      position: relative;

      .mobileMenu {
        background-color: $c_white_bg;
        flex-direction: column;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        padding: 15px 15px 15px 15px;
        z-index: 10;
        box-shadow: 0 3px 5px $c_shadow;

        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            margin-right: 8px;
          }

          a {
            color: $c_black;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
          }

          &.sale {
            border-radius: $b_radius_default;
            background-color: $c_sale;
            padding: 5px 10px;
            a {
              color: $c_white;
            }
          }
        }
      }

      .mainBar {
        width: auto;
        justify-content: space-between;
        align-items: center;
      }

      .barContainer {
        width: 100%;
      }

      .hamburgerWrap {
        position: relative;
        display: block;
        width: 70px;

        .hamburger {
          width: 30px;
          height: 20px;
          position: relative;
          transform: rotate(0deg);
          transition: 0.5s ease-in-out;
          top: 5px;

          span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: white;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
              top: 0;
            }

            &:nth-child(2) {
              top: 9px;
            }

            &:nth-child(3) {
              top: 9px;
            }

            &:nth-child(4) {
              top: 17px;
            }
          }
        }
      }

      .mainBar {
        justify-content: space-between;
        height: auto;
        margin-top: 10px;

        .actionForm {
          display: none;
        }

        .brand {
          margin-right: 0;
          text-align: center;

          a {
            display: block;
            text-align: center;

            img {
              width: 75%;
              display: inline-block;
              &:last-child {
                display: none;
              }
            }

            &:hover {
              img {
                &:first-child {
                  display: none;
                }
                &:last-child {
                  display: inline-block;
                }
              }
            }
          }
        }

        .iconActions {
          .item {
            &:after {
              display: none;
            }
          }

          .iconCart {
            padding: 0;
            height: 51px;

            span.cartCount:first-child {
              top: 5px;
            }
          }
        }
      }

      .mobileSearchForm {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 15px 0 10px 0;

        .mobileSearch {
          width: 100%;

          input {
            width: 100%;
            height: 40px;
            border-radius: $b_radius_default;
          }
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .header {
    .mainBarWrap {
      .barContainer {
        margin: 0;
      }
    }
  }
}
