@import "styles/variables";

.footer {
  padding: 50px 0 40px;
  background-color: $c_black_bg;

  .newsletter {

    h2 {
      color: $text_white;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }

    p {
      color: $text_white;
      max-width: 680px;
      margin: auto;
      font-size: $f_size_base;
      line-height: 20px;
    }

    .actionForm {
      margin-top: 40px;
    }    

    // div {
    //   margin-top: 45px;
    // }

    .btn {
      margin-right: 0;
    }
  }

  .benefitIcons {
    margin-top: 70px;

    .benefitIconsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      border-top: 1px solid $c_dark_green_border;
      border-bottom: 1px solid $c_dark_green_border;

      .box {
        display: flex;
        align-items: center;
        margin-right: 60px;

        img {
          margin-right: 10px;
        }

        p {
          color: $text_white;
          margin-bottom: 0;
          line-height: 20px;
        }
      }
    }
  }

  .links {
    margin-top: 70px;

    .colWrapper {
      display: flex;

      .col {
        //width: calc(100% / 4);

        padding-right: 15px;

        &:first-child {
          width: 35%;
        }

        &:nth-child(2) {
          width: 26%
        }

        &:nth-child(3) {
          width: 26%;
        }

        &:last-child {
          width: 12%;
        }

        .brand {
          margin-bottom: 35px;
        }

        h5 {
          font-size: 20px;
          line-height: 25px;
          color: $c_green;
          margin-bottom: 10px;
        }

        p {
          max-width: 60%;
          font-size: $f_size_base;
          line-height: 20px;
          color: $text_white;
          margin-bottom: 30px;
        }

        ul {

          li {
            font-size: $f_size_base;
            line-height: 20px;
            color: $text_white;
            margin-bottom: 5px;

            a {

            }
          }
        }

        .socialContact {
          margin-top: 25px;

          .social {

            a {
              display: inline-block;
              margin-right: 20px;

              svg {
                fill: $c_white;
                width: 27px;
                height: 27px;

                path, circle {
                  fill: $c_white;
                }
              }
            }
          }

          .contact {
            margin-top: 25px;
            display: flex;
            flex-direction: column;

            a {
              display: flex;
              align-items: center;
              font-size: $f_size_base;
              font-weight: bold;
              color: $text_white;
              margin-bottom: 5px;
              transition: all .2s ease;

              &:hover {
                color: $c_green;
                text-decoration: underline;
              }

              svg {
                margin-right: 7px;
              }
            }
          }
        }
      }
    }
  }

  .logos {
    margin-top: 80px;

    .logosWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .copyright {
    margin-top: 50px;

    p {
      font-size: 12px;
      line-height: 15px;
      color: $text_gray_dark;
      margin-bottom: 0;
    }
  }
}

@media ( max-width: $xxl ) {

  .footer {

    .links {

      .colWrapper {
        display: flex;
        flex-wrap: wrap;

        .col {

          &:first-child {
            width: 40%;
          }

          &:nth-child(2) {
            width: 22.5%;
          }

          &:nth-child(3) {
            width: 22.5%;
          }

          &:last-child {
            width: 15%;
          }

          .socialContact {

            a {
              white-space: nowrap !important;
            }
          }
        }
      }
    }
  }
}

@media ( max-width: $xl ) {

  .footer {

    .benefitIcons {
      margin-top: 70px;

      .benefitIconsWrapper {
        flex-wrap: wrap;

        .box {
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .logos {
      margin-top: 80px;

      .logosWrapper {
        flex-wrap: wrap;

        img {
          margin-bottom: 20px;
        }
      }
    }

    .copyright {
      margin-top: 30px;
    }
  }
}

@media ( max-width: $lg ) {

  .footer {

    .benefitIcons {

      .benefitIconsWrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .box {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .links {

      .colWrapper {
        display: flex;
        flex-wrap: wrap;

        .col {


          &:first-child {
            width: 100%;
            margin-bottom: 50px;

            ul {
              margin-top: 40px;
            }
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(3) {
            width: 50%;
          }

          &:last-child {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 50px;

            div {
              width: 50%;
            }

            .socialContact {

              .social, .contact {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media ( max-width: $md ) {

  .footer {

    .links {
      margin-top: 30px;

      .colWrapper {
        display: flex;
        flex-wrap: wrap;

        .col {

          &:first-child {
            width: 100%;
            margin-bottom: 50px;

            ul {
              margin-top: 40px;
            }
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(3) {
            width: 50%;
          }

          &:last-child {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 50px;

            div {
              width: 50%;
            }
          }

          .socialContact {
            margin-top: 25px;

            .contact {

              a {
                white-space: nowrap !important;
              }
            }
          }
        }
      }
    }

    .logos {

      .logosWrapper {

        img {
          padding: 10px 15px;
          margin: 0;
        }
      }
    }
  }
}

@media ( max-width: $sm ) {

  .footer {

    .newsletter {

      .newsletterWrapper {
        margin-top: 0;
      }

      h2 {
        font-size: 20px;
        line-height: 25px;
        margin-top: 0;
        text-align: center;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }

      .actionForm {
        margin-top: 20px;
      }
    }

    .benefitIcons {
      margin-top: 40px;
    }
  }
}