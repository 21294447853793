@import "~bootstrap-4-grid";
@import "variables";
@import "helpers";
// @import "~rsuite/dist/styles/rsuite-default.min.css";

*, ::after, ::before {
  box-sizing: inherit;
}

body {
  background-color: #f3f3f3;
  color: $c_black;
  font-family: $f_family_primary;
  font-size: $f_size_base;
  line-height: $line_height_base;
  margin: 0;
}

::selection {
  color: black;
  background: #B4D5FE;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f_family_primary;
  color: $text_black;
  font-weight: bold;
  margin-top: 0;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;

  &.special {

    li {
      display: flex;
      align-items: center;

      &:before {
        content: url('/img/icon-ok.svg');
        margin-right: 10px;
      }
    }
  }
}

form {

  input {
    background-color: $c_white_bg;
    border: none;

    &:focus {
      outline: none;
    }
  }
}


.custom-container, .container {
  //width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;

  &.sliderContainer {
    margin: 0 0 0 auto;
    padding: 0;
  }

  @media ( min-width: $sm ) {
    max-width: 540px;
  }

  @media ( min-width: $md ) {
    max-width: 720px;
  }

  @media ( min-width: $lg ) {
    max-width: 960px;
  }

  @media ( min-width: $xl ) {
    max-width: 1140px;

    &.sliderContainer {
      max-width: 1170px;
    }
  }

  @media ( min-width: $xxl ) {
    max-width: 1320px;

    &.sliderContainer {
      max-width: 1420px;
    }
  }

  @media ( min-width: $xxxl ) {
    max-width: 1600px;

    &.sliderContainer {
      max-width: 1630px;
    }
  }
}

.col-xxl-3 {

  @media ( min-width: $xxxl ) {

    & {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

section.favouriteProducts {
  background-color: $c_main_bg;
}

section.benefitProducts {
  background-color: $c_white_bg;

  .benefitBox {

    .benefitBadges {

      div:before {
        background: $c_white_bg !important;
      }
    }
  }
}

section.newsProducts {
  background-color: $c_main_bg;
}

section.blogPosts {
  background-color: $c_main_bg;

  ul {
    padding-left: 0 !important;
  }

  a {
    display: block;
    position: relative;
  }

  .blogPostBox{
    width: 460px;
    margin-right: 15px;
  }

}

.carouselArrows {

  button {
    display: flex;
    align-items: center;
    border: 1px solid $c_white;
    border-radius: $b_radius_default;
    background-color: transparent;

    &:hover {
      background-color: $c_white_bg;

      img {
        filter: grayscale(1) invert(1);
      }
    }

    &:focus {
      outline: none;
    }

    &.arrowPrev {
      margin-right: 10px;
    }
  }
}

.promoSlider {

  .promoCarousel {
    height: 100%;

    ul, li {
      height: 100%;
    }

    .carouselArrows {
      display: flex;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}

.productSlider {
  position: relative;

  .productCarousel {
    padding-top: 3px;
    padding-bottom: 140px;

    ul {
      padding-left: 7px;
    }

    .react-multi-carousel-dot-list {
      display: flex;
      justify-content: flex-start;
      width: auto;
      position: absolute;
      left: 0;
      top: 84%;
      padding-left: 10px;

      li {
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }

        button {
          background-color: $text_light_gray;
          border: none;
          width: 14px;
          height: 14px;
        }

        &.react-multi-carousel-dot--active {

          button {
            background-color: $c_green_bg;
          }
        }
      }
    }
  }

  .carouselArrows {
    display: flex;
    position: absolute;
    right: 0;
    top: 84%;
    padding-right: 150px;
    z-index: 10;

    button {
      border: 1px solid $c_black;

      &:hover {
        background-color: $c_black;

        img {
          filter: grayscale(0) invert(0);
        }
      }

      img {
        filter: grayscale(1) invert(1);
      }
    }
  }
}

.sliderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.hasCategories {
    justify-content: flex-start;
    align-items: flex-start;

    h2 {
      margin-right: 30px;
    }
  }

  h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 38px;
  }

  a {
    display: flex;
    align-items: center;
    color: $c_black;
    font-size: $f_size_base;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 40px;

    &:after {
      content: '+';
      margin-left: 3px;
      position: relative;
      top: 1px;
    }

    &.imageTitle:after {
      display: none;
    }
  }

  .categories {
    display: flex;
    align-items: center;

    a {
      display: flex !important;
      align-items: center !important;
      margin-right: 30px;
      margin-bottom: 0;

      &.active {
        color: $c_white !important;
        background-color: $c_black !important;

        &:hover {
          color: $c_white !important;
          background-color: $c_black !important;

          svg {
            fill: $c_white !important;

            rect, path, circle {
              fill: $c_white !important;
            }
          }
        }

        svg {
          fill: $c_white !important;

          rect, path, circle {
            fill: $c_white !important;
          }
        }
      }

      &:hover {
        color: $c_black !important;
        background-color: $c_white_bg !important;

        svg {
          fill: $c_black !important;
          transform: none !important;

          path, rect, circle {
            fill: $c_black !important;
          }
        }
      }

      svg {
        margin-right: 10px;
      }

      &:after {
        display: none;
      }
    }
  }

  @media ( max-width: $xxl ) {

    &.hasCategories {

      h2 {
        font-size: 32px;
        line-height: 1.2;
      }
    }

    .categories {
      flex-wrap: wrap;

      a {
        margin-bottom: 10px;
      }
    }
  }

  @media ( max-width: $md ) {

    &.hasCategories {
      flex-direction: column;
    }

    h2 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    img {
      display: none;
    }
  }
}

.showMore {
  color: $c_black;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 34px;

  &:after {
    content: '+';
    margin-left: 3px;
    position: relative;
    top: 1px;
  }
}

.brands {

  .logoCarousel {

    ul {

      li {
        text-align: center;
      }
    }
  }
}

.testimonialsCarousel {

  .testimonialsCarousel {
    padding-bottom: 50px;

    .carouselArrows {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 75%;
      z-index: 30;
      justify-content: center;
      width: 100%;

      button {
        border: 1px solid $c_black !important;
      }
    }
  }
}

.iconColor {
  fill: $c_icon;
}

#hamburger {

   &.open {

    span {
      &:nth-child(1) {
        top: 9px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0;
        left: 50%;
      }
    }
  }
}

.phone-input-component {
  .ant-form-item-control-input-content {
    border-radius: $b_radius_default !important; 
  }
  label {
    padding-left: 40px !important;

    &.label-float-custom {
      padding-left: 5px !important;
    }
  }
}

@import "components";
@import "responsiveGlobals";
