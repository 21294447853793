.floatLabel {
  position: relative;

  .label {
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 1px 5px;
    left: 5px;
    top: 50%;
    transition: 0.2s ease all;
    background: transparent;
    color: rgba(0,0,0,0.4);
    transform: translateY(-50%);
    z-index: 1;
  }

  .labelFloat {
    top: 0;
    font-size: 11px;
    background: #ffffff;
    padding: 2px 6px;
  }
}
