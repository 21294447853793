@import "styles/variables";

$height : 500px;

.search-wrapper {
  
  position: relative;

  form{
    button{
      width: 80px;
      margin-right: 50px;
      input{
        &.mobile{
            width: 100%;
            height: 40px;
            border-radius: $b_radius_default;
        }
      }
      &.mobile{
        position: absolute;
        top: 0px;
        right: 0;
        background-color: transparent;
        margin: 0;
        width: auto;
      }
    }
  }

  .results {
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    z-index: 20;
    width: 100%;
    .resultsWrapper {
      position: relative;
      display: flex;
      width: 100%;
      background: white;
      flex-direction: column-reverse;
      border-radius: $b_radius_default;
      box-shadow: $general-shadow;

      @media (min-width: $lg) {
        flex-direction: row;
        width: 960px;
        height: $height;
        margin-left: -300px;
      }

      @media (min-width: $xxxl) {
        margin-left: -150px;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        color: $grey-color;
        display: block;
        font-size: 20px;
        cursor: pointer;
      }

      h2 {
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        margin: 15px 0;
      }

      .resultsLeft {
        width: 100%;
        background: rgba($grey-color, 0.3);
        padding: 10px 20px;
        border-bottom-right-radius: $b_radius_default;
        border-bottom-left-radius: $b_radius_default;

        @media (min-width: $md) {
          width: 100%;
          height: calc($height - 100px);
          overflow: scroll;
        }

        @media (min-width: $lg) {
          width: 30%;
          height: $height;
          border-top-left-radius: $b_radius_default;
          border-bottom-left-radius: $b_radius_default;
          border-bottom-right-radius: 0;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 20px 0;

          &.inline {
            width: 100%;

            li {
              display: inline-flex;
              margin: 5px 5px 0 0;
            }
          }

          li {
            list-style: none;

            a {
              padding: 5px 0;
              color: black;

              &:hover {
                text-decoration: underline;
              }

              &.tag {
                border-radius: 15px;
                background: white;
                padding: 5px 10px;
                font-size: 13px;

                &:hover {
                  background: black;
                  color: white;
                }
              }
            }
          }
        }
      }

      .resultsRight {
        width: 100%;
        background: white;
        padding: 10px 20px;
        border-top-left-radius: $b_radius_default;
        border-top-right-radius: $b_radius_default;
        height: $height;
        overflow: scroll;

        @media (min-width: $lg) {
          width: 70%;
          border-bottom-left-radius: 0;
          border-top-right-radius: $b_radius_default;
          border-bottom-right-radius: $b_radius_default;
        }
      }
    }
  }
}
